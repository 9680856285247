if (document.querySelector("#locations-map")) {
  let APIKey = document.querySelector("#locations-map").getAttribute("data-api");

  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKey}&callback=initMap`;
  script.defer = true;
  document.head.appendChild(script);
}

window.initMap = () => {
  let options = {
    zoom: 4,
    center: new google.maps.LatLng(47, -88),
    disableDefaultUI: true,
    backgroundColor: "#B9D9EB"
  };

  const map = new google.maps.Map(document.querySelector("#locations-map"), options);
  const bounds = new google.maps.LatLngBounds();
  const infoWindow = new google.maps.InfoWindow();

  myLocations?.forEach(loc => {
    addMarker(loc, map, bounds, infoWindow)
  });

  map.fitBounds(bounds);
}

function addMarker(loc, map, bounds, infoWindow) {
  const icons = {
    gulf: {
      url: "/images/gulfcanada.ca/gulf-icon.png",
      scaledSize: {
        width: 28,
        height: 28
      }
    },
    xtr: {
      url: "/images/gulfcanada.ca/xtr-icon.png",
      scaledSize: {
        width: 28,
        height: 28
      }
    }
  }

  let icon = loc.type === "XTR" ?  icons.xtr : icons.gulf;
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(loc.latitude, loc.longitude),
    map,
    title: loc.name,
    icon: icon
  });

  const infoString = `
  <div class="text-ms-1">
    <p class="font-bold text-ms0 m-0">${loc.name}</p>
    <p class="mt-ms-4">${loc.address}<br />
      ${loc.city}, ${loc.state} ${loc.postalCode}</p>
  </div>
`;

  marker.addListener("click", () => {
    infoWindow.close();
    infoWindow.setContent(infoString);
    infoWindow.open({
      anchor: marker,
      map: map,
      shouldFocus: true
    });
  });

  bounds.extend(marker.position);
}
