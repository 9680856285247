const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let scrollPosition;

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.site-header [aria-controls="primaryNavigation"]`);
  if (!button) {
    return;
  }

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].site-header");

  if (!isOpen) {
    scrollPosition = window.scrollY;
  }

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  //$("body").classList.toggle("overflow-hidden");

  if (isOpen) {
    window.scrollTo({top: scrollPosition, behavior: "instant"});
  } else {
    window.scrollTo({top: 0, behavior: "instant"});
  }
});

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".site-header:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  closest.classList.add("open");
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".site-header:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  closest.classList.remove("open");
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".site-header a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    let button = $("button", item);
    item.classList.remove("open");
    if (button) {
      button.setAttribute(`aria-expanded`, `false`);
    }
  })

  $navItem.classList.toggle(`open`);
  if ($toggle.getAttribute(`aria-expanded`) === `true`) {
    $toggle.setAttribute(`aria-expanded`, `false`);
  } else {
    $toggle.setAttribute(`aria-expanded`, `true`);
  }
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".site-header.menu-open nav .isParent > a");
  if (!closest) return;

  const $navItem = closest;
  const $parent = $navItem.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($parent.classList.contains(`open`)) {
    $parent.classList.remove(`open`);
  } else {
    $parent.classList.add(`open`)
    siblings.forEach(sibling => {
      sibling.classList.remove("open");
    })
  }
}, false);
